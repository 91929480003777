import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-acesso-negado',
  templateUrl: './acesso-negado.component.html'
})
export class AcessoNegadoComponent { 
  constructor(private titleService: Title) {
    this.titleService.setTitle("ACME - RPA Brasil - Acesso negado.");
  }
}
