import { NgModule } from '@angular/core';
import { SortableHeader } from './sortable.directive';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [ 
    SortableHeader
  ],
  imports: [
    NgbModule],
  providers: [],
  exports: [SortableHeader]
})
export class PaginationModule { }
