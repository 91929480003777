import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { OkCancelDialog } from './ok-cancel-dialog';

@NgModule({
  imports: [
    CommonModule, 
    NgbModule
  ],
  providers: [NgbActiveModal],
  declarations: [OkCancelDialog],
  exports: [OkCancelDialog],
  bootstrap: [OkCancelDialog]
})
export class OkCancelDialogModule {}
