export class LocalStorageUtils {
    
    public static obterUsuario() {
        let user = localStorage.getItem('devio.user');
        return JSON.parse(user);
    }

    public static salvarDadosLocaisUsuario(response: any) {
        let userToken = response.userToken;
        let accessToken = response.accessToken;

        LocalStorageUtils.salvarTokenUsuario(accessToken);
        LocalStorageUtils.salvarUsuario(userToken);
    }

    public static limparDadosLocaisUsuario() {
        localStorage.removeItem('devio.token');
        localStorage.removeItem('devio.user');
    }

    public static obterTokenUsuario(): string {
        return localStorage.getItem('devio.token');
    }

    public static salvarTokenUsuario(token: string) {
        localStorage.setItem('devio.token', token);
    }

    public static salvarUsuario(user: string) {
        localStorage.setItem('devio.user', JSON.stringify(user));
    }

    public static temAcesso(claimNome: string, claimValor: string): boolean {
        let user: any = LocalStorageUtils.obterUsuario();

        if( !user || user === undefined || ! user.claims){
            return false;
        }

        let claim = user.claims.find(c => c.type === claimNome);
        if(!claim){
            return false;
        }

        let valores: string = claim.value as string;

        return valores.includes(claimValor);

    }

}