import {Component, Input} from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ok-cancel-dialog',
  templateUrl: './ok-cancel-dialog.html'
})
export class OkCancelDialog {
  @Input() 
  public content: string;

  @Input() 
  public title: string;

  constructor(public activeModal: NgbActiveModal) {}
}
