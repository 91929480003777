import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { NgbModule, NgbActiveModal, NgbModal, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavegacaoModule } from './navegacao/navegacao.module';
import { OkCancelDialogModule } from './modals/ok-cancel-dialog/ok-cancel-dialog.module';
import { HttpErrorInterceptor } from './services/HttpErrorInterceptor';
import { PaginationModule } from './table-navigation/pagination-module';

import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
registerLocaleData(localePt);

export const httpInterceptorsProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
]

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NavegacaoModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 200,
      preventDuplicates: true,
      closeButton: true,
      progressBar: false
    }) ,
    OkCancelDialogModule,
    NgxSpinnerModule,
    HttpClientModule,
    PaginationModule
  ],
  providers: [
    NgbActiveModal,
    NgbModal,
    httpInterceptorsProviders  ,
    { provide: LOCALE_ID, useValue: "pt-BR" }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
