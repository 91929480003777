<!-- Page Content -->
<header class="bg-primary py-5 mb-5">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-lg-3">
                <img src="/assets/acme_logo.png" />
            </div>
            <div class="col-lg-9">
                <h1 class="display-5 text-white mt-5 mb-2">Sistema ACME - RPA Brasil</h1>
                <p class="lead mb-5 text-white">Portal para exercícios e treinamentos de RPA.</p>
            </div>
        </div>
    </div>
</header>

<div class="container main-container">
    <div class="row text-center">

        <div class="col-lg-3 col-md-6 mb-4">
            <div class="card h-100">
                <img class="card-img-top" src="/assets/acme_produtos.png" alt="Produtos">
                <div class="card-body">
                    <h4 class="card-title">Variedade</h4>
                    <p class="card-text">A ACME possui uma imensa variedade de produtos. São produtos de alto padrão e excelente qualidade. Venha conferir.</p>
                </div>
                <div class="card-footer">
                    <a href="#" class="btn btn-primary">Saiba mais</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-4">
            <div class="card h-100">
                <img class="card-img-top" src="/assets/papa_leguas.png" height="164px" width="253px" alt="">
                <div class="card-body">
                    <h4 class="card-title">Eficiência</h4>
                    <p class="card-text">Contando uma equipe altamente qualificada e profissionais de alta performance, a ACME possui a entrega mais rápida do mercado.</p>
                </div>
                <div class="card-footer">
                    <a href="#" class="btn btn-primary">Saiba mais</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-4">
            <div class="card h-100">
                <img class="card-img-top" src="/assets/coyote.png" height="164px" width="253px" alt="">
                <div class="card-body">
                    <h4 class="card-title">Inteligência</h4>
                    <p class="card-text">As soluções de logística da ACME são reconhecidas através de prêmios internacionais e utilizadas por milhares de parceiros em todo o globo.</p>
                </div>
                <div class="card-footer">
                    <a href="#" class="btn btn-primary">Saiba mais</a>
                </div>
            </div>
        </div>

        <div class="col-lg-3 col-md-6 mb-4">
            <div class="card h-100">
                <img class="card-img-top" src="/assets/coyote2.png" height="164px" width="253px" alt="">
                <div class="card-body">
                    <h4 class="card-title">Confiança</h4>
                    <p class="card-text">Venha para a ACME! Sua parceira mais fiel para o sucesso dos seus negócios. Confiança construída com respeito.</p>
                </div>
                <div class="card-footer">
                    <a href="#" class="btn btn-primary">Saiba mais</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fim Content -->