
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Cross')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div [innerHTML]="content"></div> 
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="activeModal.close('Cancel')">Cancelar</button>
      <button type="button" class="btn btn-danger" (click)="activeModal.close('Ok')">Ok</button>
    </div>

  
