import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LocalStorageUtils } from '../utils/localstorage';
import { Router } from '@angular/router';


@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        return next.handle(req).pipe( catchError(err => {
                if (err instanceof HttpErrorResponse){
                    if (err.status === 401){
                         LocalStorageUtils.limparDadosLocaisUsuario();
                         this.router.navigate(['/conta/login'], { queryParams: { returnUrl: this.router.url }})
                    }
                    if (err.status === 403){
                        LocalStorageUtils.limparDadosLocaisUsuario();
                        this.router.navigate(['/acesso-negado'])
                    }
                    if (err.status === 404){
                        this.router.navigate(['/nao-encontradoJr'])
                    }
                }
                return throwError(err);
            })
        );
    }

}